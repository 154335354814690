import * as React from 'react'
import {Link} from "gatsby";

const Menu = ({ items, tagStyle=null }) => {
  let wrapperClass = 'flex items-center justify-center flex-wrap max-w-6xl mx-auto'

  if (tagStyle) {
    wrapperClass += ' -mb-3'
  }

  let linkClass = 'inline-flex px-3 py-2'

  if (tagStyle) {
    linkClass += ' border border-blue-600 rounded-full mr-3 mb-3'
  }

  return (
    <div className={wrapperClass}>
      {
        items.map(node => (
          <Link
            className={linkClass}
            key={node.id}
            to={ node.uri }>{ node.name }</Link>
        ))
      }
    </div>
  )
}

export default Menu