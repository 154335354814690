import * as React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import Menu from "../components/menu";

const PagesMenu = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPage(filter: {parentDatabaseId: {eq: null}}) {
        nodes {
          id
          title
          uri
        }
      }
    }
  `)

  const pages = data.allWpPage.nodes.map(page => {
    return {
      id: page.id,
      name: page.title,
      uri: decodeURI(page.uri.replace('/index.php', ''))
    }
  })

  const homePage = {
    id: 'home',
    name: 'Главная',
    uri: '/'
  }

  const publishersPage = {
    id: 'publishers',
    name: 'Издательства',
    uri: '/publishers'
  }

  return (
    <Menu items={[homePage, ...pages, publishersPage]}/>
  )
}

export default PagesMenu