import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PagesMenu from '../components/pagesMenu'
import Menu from '../components/menu'
import { Helmet } from 'react-helmet'

const Title = ({pageTitle}) => {
  if (pageTitle) {
    return (
      <h1 className="my-24 text-5xl font-bold text-center max-w-6xl mx-auto">{ pageTitle }</h1>
    )
  }
  return null
}

const CatsMenu = ({ cats }) => {
  const items = cats.map(({id, name, slug}) => {
    return {
      id,
      name,
      uri: `/products/${decodeURI(slug)}/`
    }
  })

  return (
    <Menu items={items}/>
  )
}

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
      allWpLattecat(filter: {parentDatabaseId: {eq: null}}) {
        nodes {
          id
          name
          slug
        }
      }
    }
  `)

  let titleProcessed = `${data.site.siteMetadata.title} - ${data.site.siteMetadata.description}`

  if (pageTitle) {
    titleProcessed = `${pageTitle} | ${titleProcessed}`
  }

  return (
    <section>
      <Helmet>
        <title>{titleProcessed}</title>
      </Helmet>

      <header className="max-w-6xl mx-auto text-center mt-12">
        <h1 className="text-3xl font-black">{data.site.siteMetadata.title}</h1>
        <h3>{data.site.siteMetadata.description}</h3>
      </header>
      <nav className="mb-12">
        <PagesMenu/>
      </nav>
      <main>
        <Title pageTitle={pageTitle}/>

        {children}
      </main>
      <footer className="my-24">
        <div className="mb-12">
          <CatsMenu cats={data.allWpLattecat.nodes} />
        </div>
        <div className="mb-12">
          <PagesMenu/>
        </div>
        <div className="text-center">
          <h1 className="text-3xl font-black">{data.site.siteMetadata.title}</h1>
          <h3>{data.site.siteMetadata.description}</h3>
        </div>
      </footer>
    </section>
  )
}
export default Layout